import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import LayoutContext from '../../context/LayoutContext';
import PageTitle from '../../components/PageTitle/PageTitle';
import Layout from '../../components/Layout';
import Link from '../../components/Link';

const Title = styled.h1`
  padding: 20px 0;
`;

const List = styled.ul`
  margin-left: 0;
  padding-left: 1.5rem;
  list-style: none;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }

  li {
    &::before {
      content: '\\2022';
      color: #8da0af;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }

    a {
      text-decoration: underline;
      font-size: 12px;
      color: #ff0000;
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

const Sitemap = ({ data }) => {
  const { t } = useTranslation();
  const { isMobileView } = useSelector((state) => state.common);
  const layoutProps = useContext(LayoutContext);
  const { gender, locale } = layoutProps.pageContext;
  const genderPath = gender === 'men' ? '/men' : '';

  return (
    <Layout fullwidth={isMobileView} seoSettings={{ title: t('site_map') }}>
      {isMobileView ? (
        <PageTitle>{t('site_map')}</PageTitle>
      ) : (
        <Title>{t('site_map')}</Title>
      )}

      <List>
        {data.allRoutesJson.nodes.map((item, index) => {
          if (!item.active) {
            return null;
          }

          return (
            <li key={index}>
              <Link to={`${genderPath}${item.path}`}>
                {t(item.breadcrumbName[0])}
              </Link>
            </li>
          );
        })}
        {data.allMenu.nodes[0].types.map((item, index) => {
          return (
            <li key={index}>
              <Link to={`${genderPath}/category/${item.slug}`}>
                {item.localizations[locale].name}
              </Link>
            </li>
          );
        })}
      </List>
    </Layout>
  );
};

export default Sitemap;

export const pageQuery = graphql`
  query($gender: String) {
    allRoutesJson(filter: { gender: { eq: $gender } }) {
      nodes {
        id
        breadcrumbName
        path
        active
      }
    }

    allMenu(filter: { slug: { eq: $gender } }) {
      nodes {
        slug
        types {
          id
          slug
          localizations {
            en {
              name
            }
            zh {
              name
            }
          }
        }
      }
    }
  }
`;
